import { Link } from 'gatsby';
import React from 'react';
import Image from '~/components/Image';

export default function ProductsSubnav({ childItems }) {
  return (
    <div>
      {childItems?.nodes?.map((child) => {
        return (
          <div key={`child-nav-${child.label}`} className={`p-6`}>
            <div
              className={`font-medium text-xxxs uppercase text-navy opacity-50 mb-0`}
            >
              {child.label}
            </div>

            <div className={`grid grid-cols-2 gap-2 -mx-3`}>
              {child?.childItems?.nodes?.map((subChild) => {
                return (
                  <Link
                    key={`child-child-nav-${subChild.label}`}
                    to={subChild.path}
                    activeClassName="active"
                    className={`text-sm text-navy hover:text-red transition duration-300 ease-in-out hover:bg-pink p-4 `}
                  >
                    <div className="flex items-center mb-2 text-base font-medium">
                      {subChild?.menuItemAcf?.icon?.localFile && (
                        <div className="flex flex-col items-center justify-center object-contain w-6 h-6 mr-4">
                          <Image data={subChild?.menuItemAcf?.icon} />
                        </div>
                      )}
                      {subChild.label}
                    </div>

                    {subChild?.description?.length > 0 && (
                      <div className="text-xs leading-normal opacity-75">
                        {subChild.description}
                      </div>
                    )}
                  </Link>
                );
              })}
            </div>
          </div>
        );
      })}
    </div>
  );
}
