import { graphql, Link, useStaticQuery } from 'gatsby';
import React, { FunctionComponent } from 'react';
import OneColSubnav from '~/components/Global/OneColSubnav';
import ProductsSubnav from '~/components/Global/ProductsSubnav';
import TwoColSubnav from '~/components/Global/TwoColSubnav';

const Nav: FunctionComponent = () => {
  const { headerNav } = useStaticQuery(graphql`
    query NavData {
      headerNav: wpMenu(locations: { eq: FOOTER_LEGAL }) {
        name
        menuItems {
          nodes {
            id
            label
            path
            parentId
            menuItemAcf {
              menuType
            }
            childItems {
              nodes {
                id
                label
                path
                childItems {
                  nodes {
                    id
                    label
                    description
                    path
                    menuItemAcf {
                      icon {
                        ...Image
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const navItems = headerNav.menuItems.nodes.filter((n) => !n.parentId);

  return (
    <nav className="hidden lg:flex lg:items-center">
      <ul>
        {navItems.map(
          ({
            label,
            childItems,
            path,
            menuItemAcf,
            isProduct = menuItemAcf.menuType === 'product',
            isTwoCol = menuItemAcf.menuType === 'two-col',
            isOneCol = menuItemAcf.menuType === 'one-col',
          }) => {
            return (
              <li key={`nav-item-${label}`} className="relative h-full">
                <Link
                  className="cursor-pointer nav-link"
                  to={path}
                  activeClassName="active"
                >
                  {label}
                </Link>

                {childItems && childItems.nodes.length > 0 && (
                  <div
                    className={`sub-menu bg-white shadow-nav opacity-0 pointer-events-none absolute left-0 -ml-8 ${
                      isProduct || isTwoCol ? 'w-screen max-w-xl' : 'w-48'
                    }`}
                  >
                    {isProduct && <ProductsSubnav childItems={childItems} />}
                    {isTwoCol && <TwoColSubnav childItems={childItems} />}
                    {isOneCol && <OneColSubnav childItems={childItems} />}
                  </div>
                )}
              </li>
            );
          }
        )}
      </ul>
    </nav>
  );
};

export default Nav;
