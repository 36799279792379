import Img from 'gatsby-image/withIEPolyfill';
import React, {
  FunctionComponent,
  ReactElement,
  useEffect,
  useRef,
  useState,
} from 'react';

interface ImageProps {
  data: {
    localFile: {
      publicURL: string;
      childImageSharp: {
        fluid: object;
      };
    };
    altText: string;
  };
  className?: string;
  style?: object;
}

const Image: FunctionComponent<ImageProps> = (props): ReactElement => {
  const image = useRef(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const { data } = props;

  useEffect(() => {
    const img = image.current;
    if (img && img.complete) {
      setIsLoaded(true);
    }
  });

  if (!data) return <></>;

  const { publicURL } = data.localFile || {};
  const { fluid } = (data.localFile || {}).childImageSharp || {};
  return (
    <>
      {fluid && <Img fluid={fluid} alt={data.altText} {...props} />}
      {!fluid && (
        <img
          ref={image}
          src={publicURL}
          alt={data.altText}
          {...props}
          onLoad={() => setIsLoaded(true)}
          style={{ opacity: !isLoaded ? 0 : 1, ...props.style }}
          className={`transition-opacity duration-700 pointer-events-none ${props.className ||
            ' '}`}
        />
      )}
    </>
  );
};

export default Image;
