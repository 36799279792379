import { graphql, Link, useStaticQuery } from 'gatsby';
import React, { FunctionComponent, ReactElement } from 'react';
import FacebookIcon from '~/images/icons/facebook.svg';
import InstagramIcon from '~/images/icons/instagram.svg';
import TwitterIcon from '~/images/icons/twitter.svg';
import Logo from '~/images/logo.svg';

const Footer: FunctionComponent = (): ReactElement => {
  const { wpMenu, wp } = useStaticQuery(graphql`
    query FooterQuery {
      wpMenu(locations: { eq: FOOTER_MENU }) {
        name
        menuItems {
          nodes {
            id
            label
            path
            parentId
            childItems {
              nodes {
                id
                label
                path
              }
            }
          }
        }
      }
      wp {
        options {
          globalOptions {
            socialLinks {
              facebookUrl
              twitterUrl
              instagramUrl
            }
            footer {
              text
            }
          }
        }
      }
    }
  `);

  const {
    footer: { text },
    socialLinks: { facebookUrl, twitterUrl, instagramUrl },
  } = wp.options.globalOptions;

  const footerNav = wpMenu.menuItems.nodes.filter((n) => !n.parentId);

  const handleOnClick = () => {
    window.displayPreferenceModal();
  };

  return (
    <footer className="text-white bg-navy">
      <div className="py-12 border-b main-footer md:py-24 border-bluefade">
        <div className="container">
          <div className="flex flex-wrap md:justify-between">
            <div className="mb-12 mr-6 lg:mb-0">
              <Link to="/" className="inline-block">
                <Logo />
              </Link>
              <div
                className="text-xs"
                dangerouslySetInnerHTML={{
                  __html: text,
                }}
              />
              <ul className="inline-flex mt-8">
                <li className="mr-4 transition duration-300 ease-in-out hover:opacity-75">
                  <a
                    href={facebookUrl}
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    <FacebookIcon className="w-4 h-4" />
                  </a>
                </li>
                <li className="mr-4 transition duration-300 ease-in-out hover:opacity-75">
                  <a
                    href={twitterUrl}
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    <TwitterIcon className="w-4 h-4" />
                  </a>
                </li>
                <li className="mr-4 transition duration-300 ease-in-out hover:opacity-75">
                  <a
                    href={instagramUrl}
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    <InstagramIcon className="w-4 h-4" />
                  </a>
                </li>
              </ul>
            </div>

            <div className="flex flex-wrap justify-between w-full text-xs md:w-auto md:justify-end">
              {footerNav.map((nav, cnt) => (
                <nav
                  key={nav.label}
                  className={`mt-8 md:mt-0 flex-auto ${
                    cnt < footerNav.length - 1 ? 'mr-12 xl:mr-16' : ''
                  }`}
                >
                  <div className="mb-4 font-medium">{nav.label}</div>
                  {nav.childItems.nodes.map((link, i) => (
                    <div
                      key={link.label + i}
                      className="mb-1"
                      style={{ maxWidth: '7rem' }}
                    >
                      <Link
                        to={link.path}
                        activeClassName="text-accent"
                        dangerouslySetInnerHTML={{ __html: link.label }}
                        className="inline-block text-xs leading-snug"
                      />
                    </div>
                  ))}
                </nav>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="py-4 mx-6 border-b-4 copyright md:py-8 border-red">
        <div className="container">
          <div className="flex flex-wrap items-center justify-center -mx-4 md:justify-between">
            <p className="mx-4 my-0 text-xxxs text-dgrey">
              Ⓒ {new Date().getFullYear()} We Are Percent Ltd.{' '}
            </p>
            <ul className="inline-flex mx-4 text-xxxs text-dgrey space-x-2">
              <li>
                <Link to="/legal">Legal</Link>
              </li>
              <li>
                <a
                  href="#"
                  onClick={handleOnClick}
                  id="termly-consent-preferences"
                >
                  Consent Preferences
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
