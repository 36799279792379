import React from 'react';

const LearnMoreLink = ({
  href,
  children,
  className,
}: {
  href: string;
  children: React.ReactNode;
  className?: string;
}) => (
  <a
    href={href}
    target="_blank"
    rel="noopener noreferrer"
    className={`text-xxs lg:text-sm underline font-medium whitespace-no-wrap ${className}`}
  >
    {children}
  </a>
);

export const GoodstackBanner = () => (
  <div className="relative flex items-center justify-center w-full bg-darkPurple text-white px-4 gap-4 overflow-hidden py-2 lg:py-0 flex-wrap lg:flex-no-wrap">
    <img
      src="/images/globe-img.png"
      alt="globe image"
      className="relative hidden lg:block"
      width="80"
      height="80"
      style={{ bottom: '-36px' }}
    />
    <p className="text-xxs lg:text-sm">
      {/* eslint-disable-next-line react/no-unescaped-entities */}
      We've rebranded 🎉 Percent is now{' '}
      <LearnMoreLink href="https://goodstack.io">Goodstack</LearnMoreLink>.{' '}
      {/* eslint-disable-next-line react/no-unescaped-entities */}
      Discover what's new{' '}
      <LearnMoreLink href="https://goodstack.io/blog/as-of-today-we-are-excited-to-announce-we-are-rebranding-to-goodstack">
        here
      </LearnMoreLink>{' '}
      👀
    </p>
    <img
      src="/images/heart-img.png"
      alt="heart image"
      className="relative hidden lg:block"
      width="80"
      height="80"
      style={{ top: '-12px' }}
    />
  </div>
);
