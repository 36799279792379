import { graphql, Link, useStaticQuery } from 'gatsby';
import React, { FunctionComponent, useState } from 'react';
import Image from '~/components/Image';
interface NavItemProps {
  description?: string;
  label: string;
  path: string;
  slug?: string;
  childItems: {
    nodes: [NavItemProps];
  };
}

interface ProductNavProps {
  nav: NavItemProps;
}

const MobileNavItem: FunctionComponent<NavItemProps> = ({
  path,
  label,
  childItems,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <Link
        className="flex-auto font-medium transition duration-300 ease-in-out cursor-pointer nav-link text-navy hover:text-red"
        to={path}
        activeClassName="active"
        onClick={(e) =>
          childItems?.nodes?.length && e.preventDefault && setIsOpen(!isOpen)
        }
      >
        {label}
      </Link>

      {childItems?.nodes?.length > 0 && (
        <div
          key={`${label}sub-menu`}
          className={`sub-menu py-6 ${isOpen && 'is-open'}`}
        >
          <div className="container">
            <div
              role="button"
              onClick={() => setIsOpen(false)}
              className="flex items-center mb-6 font-medium transition duration-300 ease-in-out back-link text-navy hover:text-red"
            >
              Back
            </div>
            {childItems?.nodes?.map((child, i) => (
              <div key={`${label}-child-child${i}`}>
                <div className="mt-8 mb-4 font-medium uppercase opacity-50 text-xxxs text-navy">
                  {child.label}
                </div>
                {i === 0 ? (
                  <div>
                    {child?.childItems?.nodes?.map(
                      ({ label, path, description, menuItemAcf }) => (
                        <Link
                          key={`child-child-nav-${label}`}
                          to={path}
                          activeClassName="active"
                          className="block mb-6 text-sm transition duration-300 ease-in-out text-navy hover:text-red"
                        >
                          <div className="flex items-center">
                            <div className="flex items-center justify-center flex-shrink-0 w-16 h-16 mr-6 bg-red bg-opacity-10">
                              <div className="w-6 h-auto">
                                <Image data={menuItemAcf.icon} />
                              </div>
                            </div>
                            <div className="w-auto">
                              <div className="block w-full font-medium">
                                {label}
                              </div>
                              <div className="leading-snug text-body">
                                {description}
                              </div>
                            </div>
                          </div>
                        </Link>
                      )
                    )}
                  </div>
                ) : (
                  <div className="grid grid-cols-2 row-gap-4 col-gap-8">
                    {child?.childItems?.nodes?.map((subChild) => {
                      return (
                        <div key={`child-child-nav-${subChild.label}`}>
                          <Link
                            to={subChild.path}
                            activeClassName="active"
                            className="block font-medium transition duration-300 ease-in-out text-navy hover:text-red"
                          >
                            {subChild.label}
                          </Link>
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

const MobileNavProducts: FunctionComponent<ProductNavProps> = ({ nav }) => {
  return (
    <>
      <div className="mb-4 font-medium uppercase opacity-50 text-xxxs text-navy">
        {nav.label}
      </div>
      {nav?.childItems?.nodes?.map(
        ({ label, path, description, menuItemAcf }) => (
          <Link
            key={`child-child-nav-${label}`}
            to={path}
            activeClassName="active"
            className="block mb-6 text-sm transition duration-300 ease-in-out text-navy hover:text-red"
          >
            <div className="flex items-center">
              <div className="flex items-center justify-center flex-shrink-0 w-16 h-16 mr-6 bg-red bg-opacity-10">
                <div className="w-6 h-auto">
                  <Image data={menuItemAcf.icon} />
                </div>
              </div>
              <div className="w-auto">
                <div className="block w-full font-medium">{label}</div>
                <div className="leading-snug text-body">{description}</div>
              </div>
            </div>
          </Link>
        )
      )}
    </>
  );
};

const MobileNav: FunctionComponent = () => {
  const { headerNav } = useStaticQuery(graphql`
    query MobileNavData {
      headerNav: wpMenu(locations: { eq: FOOTER_LEGAL }) {
        name
        menuItems {
          nodes {
            id
            label
            path
            parentId
            menuItemAcf {
              menuType
            }
            childItems {
              nodes {
                id
                label
                path
                childItems {
                  nodes {
                    id
                    label
                    description
                    path
                    menuItemAcf {
                      icon {
                        ...Image
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const mobNavItems = headerNav.menuItems.nodes.filter((n) => !n.parentId);

  return (
    <div className="items-start py-6 overflow-scroll mob-nav">
      <div className="container flex flex-col justify-between h-full">
        <ul>
          {mobNavItems.map(
            ({
              label,
              path,
              childItems,
              menuItemAcf: { menuType },
              isProduct = menuType === 'product',
            }) => (
              <li
                key={`mob-nav-item-${label}`}
                className={`${label !== 'Blog' &&
                  label.toLowerCase()} ${!isProduct &&
                  'flex items-center justify-between'} mb-4`}
              >
                {isProduct &&
                  childItems?.nodes?.map((nav, i) => (
                    <MobileNavProducts key={`product-child-${i}`} nav={nav} />
                  ))}

                {!isProduct && (
                  <MobileNavItem
                    label={label}
                    path={path}
                    childItems={childItems}
                  />
                )}
              </li>
            )
          )}
        </ul>

        <div className="grid w-full grid-cols-2 gap-4 p-4 bg-lgrey">
          <a href="#login" className="btn alt">
            Login
          </a>
          <Link
            to="https://poweredbypercent.zendesk.com/hc/en-us"
            className="btn"
          >
            Support
          </Link>
        </div>
      </div>
    </div>
  );
};

export default MobileNav;
